html,
body,
#root {
  width: 100%;
  height: 100%;
  display: block;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}